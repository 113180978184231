<template>
  <div>
    <b-card no-title>
      <b-row>
        <b-col
            xs="12"
            class="mx-auto"
        >
          <b-card-title>
            {{ $t('card.title.modify.option') }}
          </b-card-title>
        </b-col>
      </b-row>
      <b-card-text>
        {{ $t('card.description.modify.option') }}
      </b-card-text>
    </b-card>

    <b-card-code>
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                  :label="formData.title"
                  :description="formData.description"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="formData.title"
                    rules=""
                    vid="value"
                >


                  <b-form-input v-if="formData.type === 'integer'"
                                v-model="formData.value"
                                type="number"
                                :state="errors.length > 0 ? false:null"
                                :placeholder="formData.title"
                  />

                  <b-form-input v-if="formData.type === 'text'"
                                v-model="formData.value"
                                :state="errors.length > 0 ? false:null"
                                :placeholder="formData.title"
                  />

                  <b-form-input v-if="formData.type === 'date'"
                                v-model="formData.value"
                                type="text"
                                placeholder="ÉÉÉÉ-HH-NN"
                                autocomplete="off"
                                :formatter="formatDateAllowedSymbols"
                                show-decade-nav
                                required
                  />

                  <b-form-input v-if="formData.type === 'time'"
                                v-model="formData.value"
                                type="time"
                                :state="errors.length > 0 ? false:null"
                                :placeholder="formData.title"
                                :formatter="formatTimeAllowedSymbols"
                  />

                  <flat-pickr v-if="formData.type === 'datetime'"
                              v-model="formData.value"
                              class="form-control"
                              :config="flatPickRConfig"
                  />

                  <b-input-group-append v-if="formData.type === 'date'">
                    <b-form-datepicker
                        v-model="formData.value"
                        size="sm"
                        show-decade-nav
                        button-only
                        right
                        :locale="$i18n.locale"
                        v-bind="labels[$i18n.locale] || {}"
                    />
                  </b-input-group-append>

                  <b-input-group-append v-if="formData.type === 'time'">
                    <b-form-timepicker
                        v-model="formData.value"
                        size="sm"
                        button-only
                        right
                        :locale="$i18n.locale"
                        v-bind="labels[$i18n.locale] || {}"
                    />
                  </b-input-group-append>

                  <v-select v-if="formData.type === 'select'"
                            v-model="formData.value"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="title"
                            :clearable="false"
                            :options="valueOptions"
                  />

                  <b-form-radio-group v-if="formData.type === 'radio'"
                                      id="value-radio-group"
                                      v-model="formData.value"
                                      :options="valueOptions"
                  />

                  <b-form-checkbox v-if="formData.type === 'checkbox'"
                                   v-model="formData.value"
                                   :state="errors.length > 0 ? false:null"
                  >
                    {{ formData.title }}
                  </b-form-checkbox>

                  <b-form-textarea v-if="formData.type === 'textarea'"
                                   v-model="formData.value"
                  ></b-form-textarea>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                {{ $t('label.button.save') }}
              </b-button>
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <b-card-code>
      <tabs
          ref="tabs"
          :entity-id="parseInt(this.$route.params.id)"
      />
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BCard, BCardTitle, BCardText, BRow, BCol, BButton, BFormCheckbox
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import tabs from '@/views/pages/_components/_tabs/_option/update.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import {formatDateAllowedSymbols, formatTimeAllowedSymbols} from "@core/utils/filter"
import labels from '@/libs/datepicker/labels'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Hungarian} from 'flatpickr/dist/l10n/hu.js'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
    tabs,
    cancelAndGOTOPreviousPage,
    flatPickr
  },
  data() {
    return {
      role: 'option',
      formData: {
        id: null,
        type: '',
        title: '',
        key: '',
        value: '',
        options: '',
        description: '',
      },
      valueOptions: [],
      flatPickRConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: Hungarian,
        allowInput: true
      },
      labels,
      formatDateAllowedSymbols,
      formatTimeAllowedSymbols,
      required,
    }
  },
  created() {
    this.loading = true
    this.$store.dispatch('fetchOption', this.$route.params.id).then(response => {
      this.formData = response.data.entity
      this.formData.id = this.$route.params.id

      if (response.data.entity.type === 'select' || response.data.entity.type === 'radio') {
        if (this.$helpers.isJsonValid(response.data.entity.options)) {
          this.valueOptions = JSON.parse(response.data.entity.options)
        } else {
          this.$store.dispatch(response.data.entity.options).then(valueResponse => {
            this.valueOptions = valueResponse

            Object.keys(this.valueOptions).forEach(key => {
              if (parseInt(this.valueOptions[key].value) === parseInt(response.data.entity.value)) {
                this.formData.value = this.valueOptions[key];
              }
            })

          })
        }
      }
      this.loading = false
    }).catch(error => {
      this.$helpers.showErrorToast(error.response.data.message)
      this.$acl.canListPermission(this.role)
          ? this.$router.push({name: 'options'})
          : this.$router.go(-1)
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let fd = {...this.formData}
          if (this.formData.type === 'select') {
            fd.value = this.formData.value.value
          }

          this.$store.dispatch('updateOption', fd).then(() => {
            this.$refs.tabs.refreshLog()
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'options'})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.v-select {
  width: 100%;
}
</style>
